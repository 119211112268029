import React from 'react';

import { Layout } from '@/components/Layout';
import { Box, Flex, Heading, Link, Button } from 'rebass/styled-components';

import { contact } from '@/cms/contact';
import { styledTheme } from '@/styledTheme';
import { ContentWrapper } from '@/components/ContentWrapper';
import { Title } from '@/components/Title';
// import { SocialTMP } from '@/components/SocialTMP';
import { cta } from '@/cms/cta';
import { underlineLinks } from '@/utils/underlineLinks';
import styled from 'styled-components';

// TODO that might be unified with CTA component

export default () => (
  <Layout>
    <Box pt={['95px', '95px', '55px']} mb={[0, 0, '140px']}>
      <ContentWrapper maxWidth={1000} mb={[4, 4, '45px']}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <Box>
            {contact.texts.map((t) => (
              <MobileHeading
                fontSize={[5, 5, 6]}
                fontWeight={700}
                fontFamily={styledTheme.fonts.body}
              >
                {t}&nbsp;
              </MobileHeading>
            ))}
          </Box>
          <Box pb={[0, 0, 2]} mt={[4]}>
            <Button variant="primary" as={Link} href={cta.helloButton.href}>
              {contact.button}
            </Button>
          </Box>
        </Flex>
      </ContentWrapper>

      <ContentWrapper maxWidth={1000} id="contact">
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          flexWrap="wrap"
          css={underlineLinks()}
        >
          <Box width={[1, 1, 1 / 3]} py={[2, 2, 3, 4]}>
            <Title color={styledTheme.colors.secondary} mb={[3]}>
              email
            </Title>
            <Flex
              color={styledTheme.colors.primary}
              fontSize={[4]}
              fontWeight={700}
            >
              <Link href={`mailto:${contact.phone}`}>{contact.email}</Link>
            </Flex>
          </Box>
          <Box py={[2, 2, 3, 4]} pl={[0, 0, 3, 4]} width={[1, 1, 1 / 3]}>
            <Title color={styledTheme.colors.secondary} mb={[3]}>
              phone
            </Title>
            <Flex
              color={styledTheme.colors.primary}
              fontSize={[4]}
              fontWeight={700}
            >
              <Link href={`tel:${contact.phone}`}>{contact.phone}</Link>
            </Flex>
          </Box>
          {/* TODO Revive this section after creating some social presence */}
          {/* <Box */}
          {/*  py={[2, 2, 3, 4]} */}
          {/*  pl={[0, 0, 3, 4]} */}
          {/*  width={[1, 1, 1 / 3]} */}
          {/*  css={` */}
          {/*    g:hover { */}
          {/*      cursor: pointer; */}
          {/*      pointer-events: bounding-box; */}
          {/*      > path { */}
          {/*        fill: ${({ theme }) => theme.colors.secondary}; */}
          {/*      } */}
          {/*    } */}
          {/*  `} */}
          {/* > */}
          {/*   <Title color={styledTheme.colors.secondary} mb={[3]}> */}
          {/*    social */}
          {/*   </Title> */}
          {/*   <SocialTMP color={styledTheme.colors.primary} /> */}
          {/* </Box> */}
        </Flex>
      </ContentWrapper>
    </Box>
  </Layout>
);

const MobileHeading = styled(Heading)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: inline;
  }
`;
